import { render, staticRenderFns } from "./BlockedCartProducts.vue?vue&type=template&id=17a69ece&"
import script from "./BlockedCartProducts.vue?vue&type=script&lang=ts&"
export * from "./BlockedCartProducts.vue?vue&type=script&lang=ts&"
import style0 from "./BlockedCartProducts.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
